.hero-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
  
    &:before {
      content: "";
      background: url("https://assets.entrepreneur.com/content/3x2/2000/1593079102-work-731198.jpg");
      background-size: cover;
      background-position: center top;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  
    h1 {
      font-size: 2.4rem;
    }
  
    p {
      font-size: 1.4rem;
      text-align: center;
    }
  
    .heading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    @media screen and (max-width: 640px) {
      h1 {
        font-size: 2rem;
      }
    }
  }
  