.mask {
    width: 100%;
    height: 100vh;
    position: relative;
  
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0.8;
    }
  }
  
  .intro-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
  
  .hero {
    height: 100%;
    width: 100%;
  
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
  
      h1 {
        font-size: 2.5rem;
        padding: 0.6rem 0 1.5rem;
      }
  
      h2 {
        font-size: 1.7rem;
        font-weight: 200;
        text-transform: uppercase;
      }
    }
  }
  
  .content {
    margin: 1rem 0.2rem;
  }
  
  @media screen and (max-width: 600px) {
    .hero {
      .content {
        h2 {
          font-size: 1.7rem;
          font-weight: 200;
          text-transform: uppercase;
          white-space: nowrap;
        }

      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row; // Por defecto, los botones se colocan en fila
    justify-content: space-between;

    @media screen and (max-width: 600px) { 
        // Cuando la pantalla es pequeña, cambia la disposición a una columna
        flex-direction: column;
    }

    .btn {
        margin: 5px;
    }
  }