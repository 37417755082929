.skills {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 40px;
    padding-bottom: 2rem;
    justify-items: center;
  
    .skill-block {
      background-color: #1a1919;
      padding: 1.3rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
  
      img {
        width: 60px;
        height: 60px;
      }
  
      h2 {
        margin-bottom: 20px;
        color: #fff;
      }
  
      .skills-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px;
        color: #fff;
  
        .skill-item {
          display: inline-flex;
          flex-wrap: wrap;
          margin: 5px;
          padding: 10px;
          background-color: #9e98983a;
          border-radius: 2px;
          transition: transform 0.3s ease-in-out;
          cursor: pointer;
          user-select: none;
        }
  
        .skill-item:hover {
          transform: scale(1.1);
        }
      }
    }
  
    @media screen and (max-width: 740px) {
      grid-template-columns: 1fr;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 2rem;
    margin-bottom: 25px;
    margin-top: 35px;
  }