.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left {
    text-align: center;
    margin: 1rem;
    padding: 1rem;
    max-width: 600px;
}

@media (max-width: 768px) {
    .about .left {
        margin: auto;
        max-width: 80%;
    }
}

.about .left p {
    margin: 1.2rem 0;
    font-size: larger;
}

.about .right {
    max-width: 700px;
}

.right .img-container {
    display: flex;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 50%;
    border: 2px solid #bcbcbc;
    border-radius: 50%
}

.right .img-profile {
    grid-column: 1 / span 8;
    grid-row: 1;
}

.contactbtns {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.btn {
    height: 50px; 
    display: flex;
    align-items: center; 
    justify-content: center;
}
  
.btn.contact-btn {
    width: 200px;
}

.contactbtns {
    display: flex;
    justify-content: center;
  }
  
  .contactbtns .btn {
    margin: 0.5rem 1rem 1rem 1rem;
    width: 200px;
  }

  @media screen and (max-width: 600px) {
    .contactbtns {
      flex-direction: column;
      align-items: center;
    }
    
    .contactbtns .btn {
      margin: 0.5rem 0 1rem 0;
    }
  }